<template>
	<modal-lateral ref="filter" titulo="Filtro">
		<div class="row mt-4 mx-3 gap-4">
			<div class="col-12">
				<p class="f-10 ml-2">Tipo de material</p>
				<el-select v-model="materilesFiltro.id_tipo_material" class="w-100">
					<el-option 
					v-for="(item, index) in filtroMateriales.tipo_materiales" 
					:key="index" 
					:value="item.id"
					:label="item.material"
					/>
				</el-select>
			</div>
			<div class="col-12">
				<p class="f-10 ml-2">Unidad de medida</p>
				<el-select v-model="materilesFiltro.id_unidad_medida" class="w-100">
					<el-option v-for="(item, index) in filtroMateriales.unidad_medida" 
					:key="index" 
					:value="item.id"
					:label="item.unidad_medida"
					/>
				</el-select>
			</div>
			<div class="col-12">
				<p class="f-10 ml-2">Rango de valor</p>
				<el-slider v-model="range_value" :min="filtroMateriales.valor_min" :max="filtroMateriales.valor_max" range :format-tooltip="formatTooltipRange"></el-slider>
			</div>
		</div>
		<div class="botones">
			<button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearFilter">
                Limpiar
            </button>
            <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterEquipos">
                Filtrar
            </button>
		</div>
	</modal-lateral>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	props: {
		accionListar: {
			type: Function,
			default: ()=>{}
		},
		clearFilter: {
			type: Function,
			default: ()=>{}
		},
	},
	data(){
		return {
		}
	},
	computed: {
		materilesFiltro:{
			get() {return this.filterMateriales},
			set(val){this.set_filter_materiales(val)}
		},
		range_value: {
			get(){
				return [
					this.materilesFiltro.valor_min,
					this.materilesFiltro.valor_max,
				]
			},
			set(val){
				this.materilesFiltro.valor_min = val[0];
				this.materilesFiltro.valor_max = val[1];
			}
		},
		...mapGetters({
            filtroMateriales: 'ventas/presencia_equipos/filtroMateriales',
            filterMateriales: 'ventas/presencia_equipos/filterMateriales',
        }),
	},
	methods: {
		...mapActions({
			Action_clean_filter_materiales: 'ventas/presencia_equipos/Action_clean_filter_materiales',
		}),
		...mapMutations({
			set_filter_materiales: 'ventas/presencia_equipos/set_filter_materiales',
		}),
		async toggle(){
			this.$refs.filter.toggle()
		},
		formatTooltipRange(val){
			return 'US $ ' + val
		},
		async filterEquipos(){
			await this.accionListar()
		}
	}
}
</script>